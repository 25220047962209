import * as actionTypes from '../actions';
import capitalize from "@mui/material/utils/capitalize";

var userData = JSON.parse(localStorage.getItem('userDetails'));
var userLastName = JSON.parse(localStorage.getItem('user'));
var initialState = {
    loggedIn: false,
    user: {
        customerId: userData?.data?.id,
        first_name: userData?.data?.first_name,
        last_name: userLastName?.user_details?.last_name,
        email: userData?.data?.email,
        avatar: '/images/avatars/avatar_7.png',
        mobile: userData?.data?.mobile,
        all_order_count: userData?.data?.all_order_count,
        total_qty_delivered: userData?.data?.total_qty_delivered,
        role: 'USER',
    },
    last_name: userLastName?.user_details?.last_name,
    detailsOfCustomer: userData?.data,
    access_token: userLastName?.access_token,
    loginFailed: false,
    updateSuccessful: false,
    isLoading: false,
    forgotPasswordMsgSent: false,
    otpSent: false,
    noUserFound: false,
    isForgotPasswordLoading: false,
    otpStatus: false,
    auth: !!localStorage.access_token,
    isOTP: false,
    isPass: false,
    referralList: [],
    referralAdd:{},
    refresh :null

};

function getUnique(array, key) {
    if (typeof key !== 'function') {
        const property = key;
        key = function (item) {
            return item[property];
        };
    }
    return Array.from(array.reduce(function (map, item) {
        const k = key(item);
        if (!map.has(k)) map.set(k, item);
        return map;
    }, new Map()).values());
}

const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SESSION_LOGIN: {
            return {
                ...state,
                auth: true,
                last_name: action.payload?.user_details?.last_name,
                access_token: action.payload?.access_token,
                otpSent: false,
                otpStatus: false,
                isPass: false
            };
        }

        case actionTypes.SESSION_LOGOUT: {
            return {
                ...state,
                isPass: false,
                loggedIn: false,
                isLoading: false,
                auth: false,
                user: {
                    role: 'GUEST',
                    otpSent: false,
                    otpStatus: false,
                }
            };
        }
        case actionTypes.DELETE_TOKEN: {
            return {
                ...state,
                loggedIn: false,
                isLoading: false,
                auth: false,
                user: {
                    role: 'GUEST',
                    otpSent: false,
                    otpStatus: false,
                }
            };
        }

        case actionTypes.SESSION_LOGIN_ERROR: {
            return {
                ...state,
                loggedIn: false,
                loginFailed: true,
                otpSent: false,
                otpStatus: false,
            };
        }
        case actionTypes.DETAILS: {
            return {
                ...state,
                detailsOfCustomer: action?.payload,
                user: {
                    customerId: action?.payload?.user_id,
                    first_name: capitalize(action?.payload?.first_name),
                    email: action?.payload?.email,
                    avatar: '/images/avatars/avatar_7.png',
                    mobile: action?.payload?.mobile,
                    all_order_count: action?.payload?.all_order_count,
                    total_qty_delivered: action?.payload?.total_qty_delivered,
                    role: 'USER',
                },
                auth: true,
                isLoading: false,
            };
        }
        case actionTypes.LOADING_FALSE: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case actionTypes.GET_OTP_FOR_LOGIN: {
            return {
                ...state,
                isLoading: false,
                otpSent: action?.payload,
                noUserFound: false,
                otpStatus: true,
            };
        }
        case actionTypes.GET_OTP_FOR_LOGIN_ERROR: {
            return {
                ...state,
                isLoading: false,
                noUserFound: action?.payload,
                otpStatus: false,
            };
        }
        case actionTypes.FORGOT_LOADING: {
            return {
                ...state,
                isForgotPasswordLoading: true,
                noUserFound: action?.payload
            };
        }
        case actionTypes.TAB_LOGIN_PASS: {
            return {
                ...state,
                isLoading: false,
                isPass: true,
                isOTP: false
            }
        }
        case actionTypes.VERIFY_USER: {
            return {
                ...state,
                isLoading: false,
                verify_user: action?.payload,
                isPass: false
            }
        }
        case actionTypes.LOGIN_USING_OTP: {
            return {
                ...state,
                isLoading: false,
                isOTP: true
            }
        }
        case actionTypes.LOADING: {
            return {
                ...state,
                isLoading: true,
                forgotPasswordMsgSent: action?.payload,
                referralList: [],
                referralAdd: {},
            };
        }
        case actionTypes.REFERRAL: {
            return {
                ...state,
                isLoading: false,
                referNext:action?.payload?.next,
                referralList:  getUnique(state?.referralList?.concat(action?.payload?.results), "id"),
                referCount:action?.payload?.count,
            };
        }
        case actionTypes.REFRESH: {
            return {
                ...state,
                refresh: action?.payload,
                isLoading: false,
            };
        }
        default: {
            return state;
        }
    }
};

export default sessionReducer;
