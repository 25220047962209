import {client_id, client_secret, reposWebApi, reposWebV2Api} from './customerAPI'
import qs from 'querystring'

export const LOADING = 'LOADING';
export const LOGIN = 'LOGIN';
export const TAB_LOGIN_PASS = 'TAB_LOGIN_PASS';
export const LOGIN_USING_OTP = 'LOGIN_USING_OTP';
export const LOADING_FALSE = 'LOADING_FALSE';
export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const DETAILS = 'DETAILS';
export const SESSION_LOGIN_ERROR = 'SESSION_LOGIN_ERROR';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const GET_OTP_FOR_LOGIN = 'GET_OTP_FOR_LOGIN';
export const REFERRAL = "REFERRAL";
export const ADD_REFERRAL = "ADD_REFERRAL";
export const GET_OTP_FOR_LOGIN_ERROR = 'GET_OTP_FOR_LOGIN_ERROR';
export const FORGOT_LOADING = 'FORGOT_LOADING';
export const VERIFY_USER = 'VERIFY_USER';
export const DELETE_TOKEN = 'DELETE_TOKEN';
export const REFRESH = 'REFRESH';

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    }
};

// eslint-disable-next-line no-unused-vars
let screenKey;

export const login = (userData, router, enqueueSnackbar, fromSignup, setAttains, attains, c_code) => {

    localStorage.clear()
    let requestBody = {};
    if (userData.mobile) {
        if (fromSignup === 'email') {
            requestBody = {
                email: userData.mobile,
                password: userData.password,
                is_email: '1',
                is_username: '0',
                client_secret: client_secret,
                client_id: client_id,
                grant_type: 'password',
            };
        } else if (fromSignup === 'mobile') {
            requestBody = {
                email: "",
                password: userData.password,
                is_email: '0',
                mobile: userData.mobile,
                is_username: 0,
                client_secret: client_secret,
                client_id: client_id,
                grant_type: 'password',
            };
        } else if (fromSignup === 'otpEmail') {
            requestBody = {
                grant_type: 'password',
                password: "123",
                is_email: 1,
                email: userData.mobile,
                is_username: '0',
                mobile: "",
                is_otp: 1,
                client_secret: client_secret,
                client_id: client_id,
                otp: userData.otp,
            };
        } else {
            requestBody = {
                grant_type: 'password',
                password: "123",
                is_email: '0',
                email: "",
                is_username: '0',
                mobile: userData.mobile,
                is_otp: 1,
                client_secret: client_secret,
                client_id: client_id,
                otp: userData.otp,
                c_code: c_code
            };
        }
    }

    return async (dispatch) => {
        dispatch({
            type: LOADING,
        });
        await reposWebApi.post('partner/v2/login', qs.stringify(requestBody), config)
            .then(async response => {
                if (response.data.expires_in === 36000) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                    localStorage.setItem('access_token', response.data.access_token);
                    localStorage.setItem('refresh_token', response.data.refresh_token);
                    await dispatch(details())
                    dispatch({
                        type: SESSION_LOGIN,
                        payload: response.data
                    });
                    router.history.push('/dashboard');
                } else {
                    await dispatch(details())
                }
            }).catch(error => {
                dispatch({
                    type: LOADING_FALSE,
                });
                if (error?.response?.status === 401) {
                    dispatch({
                        type: LOADING_FALSE,
                    });
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                } else if (error?.response?.status === 406) {
                    setAttains(attains - 1)
                    dispatch({
                        type: LOADING_FALSE,
                    });
                    if (requestBody.is_otp === 1) {
                        dispatch({
                            type: LOADING_FALSE,
                        });
                        if (error?.response?.data?.exception) {
                            enqueueSnackbar(error?.response?.data?.exception, {
                                variant: 'error', anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right'
                                },
                                preventDuplicate: true
                            });
                        } else {
                            enqueueSnackbar("please Enter Valid OTP", {
                                variant: 'error', anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right'
                                },
                                preventDuplicate: true
                            });
                        }
                    } else if (error?.response?.data?.exception) {
                        enqueueSnackbar(error?.response?.data?.exception, {
                            variant: 'error', anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            preventDuplicate: true,

                        });
                    } else {
                        enqueueSnackbar(error?.response?.data?.Authorization, {
                            variant: 'error', anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            preventDuplicate: true
                        });
                        dispatch({
                            type: SESSION_LOGIN_ERROR,
                            payload: error
                        });
                    }
                } else if (error?.response?.status === 429) {
                    dispatch({
                        type: SESSION_LOGIN_ERROR,
                        payload: error
                    });
                    enqueueSnackbar(error?.response?.data?.exception, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        preventDuplicate: true
                    })
                } else {
                    dispatch({
                        type: SESSION_LOGIN_ERROR,
                        payload: error
                    });
                }

            })
    }
};

/**
 * Resets the form and dispatches an action to clear the session login error.
 *
 * @return {Promise<void>} A promise that resolves once the action is dispatched.
 */
export const formReset = () => {
    return async (dispatch) => {
        dispatch({
            type: SESSION_LOGIN_ERROR,
        });
    }
}

/**
 * Refreshes the user token and performs actions based on the response.
 *
 * @param {function} funCall - The function to call after token refresh.
 * @return {function} A function that dispatches actions based on the token refresh response.
 */
export const refreshToken = (funCall) => {
    if (localStorage.access_token) {
        return async (dispatch) => {
            dispatch({
                type: LOADING,
            });
            let requestBody = {
                refresh_token: localStorage.refresh_token,
                client_secret: client_secret,
                client_id: client_id,
            };
            await reposWebApi.post('/get/refresh/token', qs.stringify(requestBody), config)
                .then(async response => {
                    if (response.data.error) {
                        await dispatch(logout())
                    } else {
                        localStorage.setItem('access_token', response.data.access_token);
                        localStorage.setItem('refresh_token', response.data.refresh_token);
                        dispatch({
                            type: SESSION_LOGIN,
                            payload: response.data
                        });
                        await dispatch(funCall)
                        // await dispatch(details())
                    }
                }).catch(err => {
                    dispatch(logout());
                })
        }
    } else return (dispatch) => {
        dispatch(logout());
    }

}

let token = ''

/**
 * Retrieves partner details and dispatches actions based on the response.
 *
 * @param {function} dispatch - A function to dispatch actions
 * @return {Promise} A promise that resolves with the partner details data
 */
export const details = () => {
    if (token) {
        token.abort();
    }

    token = new AbortController();
    const signal = token.signal;

    return async (dispatch) => {
        await reposWebV2Api.get('/partner/partner_detail', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            signal: signal,
        }).then(res => {
            localStorage.setItem('userDetails', JSON.stringify(res?.data));
            localStorage.setItem('notification', parseInt(0));
            dispatch({
                type: DETAILS,
                payload: res?.data?.data
            })
        }).catch(error => {
            if (error?.response?.status === 401) {
                dispatch(refreshToken(details()))
            }
            if (error?.response?.state === 502 || error?.response?.state === 504) {
                dispatch(logout());
            }
        });
    }
};

/**
 * Checks if a string contains only numbers.
 *
 * @param {string} str - The string to be checked.
 * @return {boolean} Returns true if the string contains only numbers, false otherwise.
 */
function containsOnlyNumbers(str) {
    return /^\d+$/.test(str);
}

/**
 * Sends an OTP to a mobile number or email ID and dispatches actions based on the response.
 *
 * @param {string} mobile - The mobile number or email ID to send the OTP to.
 * @param {string} c_code - The country code of the mobile number.
 * @param {function} enqueueSnackbar - A function to display a snackbar message.
 * @return {function} A function that takes a dispatch function as an argument and returns a Promise.
 */
export const getOtp = (mobile, c_code, enqueueSnackbar) => {

    let requestBody;
    if (containsOnlyNumbers(parseInt(mobile))) {
        requestBody = {
            mobile: mobile,
            c_code: c_code
        };
    } else {
        requestBody = {
            mobile: mobile,
        };
    }

    return (dispatch) => {
        reposWebApi.post('/partner/v2/login/otp', qs.stringify(requestBody), config)
            .then(response => {
                if (response?.status === 200) {
                    // setLoading(false);
                    enqueueSnackbar('OTP Sent Successfully to registerd mobile number or emailId', {
                        variant: 'Success', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    })
                    dispatch({
                        type: GET_OTP_FOR_LOGIN,
                        payload: true
                    });
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                }
                if (error?.response?.status === 406) {
                    enqueueSnackbar(error?.response?.data?.data, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    })
                    dispatch({
                        type: GET_OTP_FOR_LOGIN_ERROR,
                        payload: true
                    });
                }
            })
    }
};

/**
 * Verifies the user by checking if the user exists, handles different scenarios based on the response status.
 *
 * @param {Object} userData - The user data containing inputName and c_code.
 * @param {Object} router - The router object.
 * @param {function} enqueueSnackbar - A function to display a snackbar message.
 * @param {string} signup_value - The signup value.
 * @return {Promise} A function that takes a dispatch function as an argument and returns a Promise.
 */
export const verifyUser = (userData, router, enqueueSnackbar, signup_value) => {
    //localStorage.clear()
    let requestBody = {};
    let queryParams = {};
    queryParams.mobile_email = userData.inputName.toLowerCase();
    queryParams.c_code = userData.c_code


    return async (dispatch) => {
        dispatch({
            type: LOADING,
        });
        await reposWebApi.get("/check_existing_user", {
            headers: {
                Authorization: "Bearer " + localStorage.access_token,
            },
            params: queryParams,
        })
            .then(async response => {
                dispatch({
                    type: VERIFY_USER,
                    payload: response?.data?.data
                });
                if (response?.data?.data?.user_exists === "YES") {
                    dispatch({
                        type: TAB_LOGIN_PASS,
                        payload: response?.data?.data?.mobile_email
                    });
                }
            }).catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                } else if (error?.response?.status === 406) {
                    dispatch({
                        type: LOADING_FALSE,
                    });
                    if (requestBody.is_otp === 1) {
                        dispatch({
                            type: LOADING_FALSE,
                        });
                        if (error?.response?.data?.exception) {
                            enqueueSnackbar(error?.response?.data?.exception, {
                                variant: 'error', anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right'
                                }
                            });
                        } else {
                            enqueueSnackbar('Invalid OTP', {
                                variant: 'error', anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right'
                                }
                            })
                        }
                    } else if (error?.response?.data?.exception) {
                        enqueueSnackbar(error?.response?.data?.exception, {
                            variant: 'error', anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            }
                        })
                    } else {
                        enqueueSnackbar(error?.response?.data?.Authorization, {
                            variant: 'error', anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            }
                        })
                        dispatch({
                            type: SESSION_LOGIN_ERROR,
                            payload: error
                        });
                    }
                } else {
                    dispatch({
                        type: SESSION_LOGIN_ERROR,
                        payload: error
                    });
                }
            })
    }
};

export const changesPage = (screen) => {
    if (screen === "otp") {
        return (dispatch) => {
            dispatch({
                type: TAB_LOGIN_PASS,
                payload: undefined
            });
        }
    } else {
        return (dispatch) => {
            dispatch({
                type: VERIFY_USER,
                payload: undefined
            });
        }
    }
}

export const changeScreen = (key, inputName) => {
    screenKey = key;
    if (key === 'OTP') {
        return (dispatch) => {
            dispatch({
                type: LOGIN_USING_OTP,
                payload: inputName
            });
        }
    } else if (key === 'loginPass') {
        return (dispatch) => {
            dispatch({
                type: TAB_LOGIN_PASS,
                payload: inputName
            });
        }
    } else {
        return (dispatch) => {
            dispatch({
                type: LOGIN,
                payload: inputName
            })
        }
    }
};

export const getReferral = (data,fetchMoreData) => {
    return async (dispatch) => {
        if (!fetchMoreData) {
            dispatch({
                type: LOADING,
                payload: false
            });
        }
        await reposWebApi.get('partner/partner_referral_detail', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            params:data
        }).then(res => {
            dispatch({
                type: REFERRAL,
                payload: res?.data?.data
            })
        }).catch(error => {
            if (error?.response?.status === 401) {
                dispatch(refreshToken())
                dispatch(getReferral())
            }
        });
    }
};

export const addReferral = (params, enqueueSnackbar, history) => {

    return (dispatch) => {
        dispatch({
            type: LOADING,
        });
        reposWebApi.post('partner/partner_referral_detail', qs.stringify(params), {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            }
        })
            .then(response => {
                dispatch({
                    type: ADD_REFERRAL,
                    payload: response?.data?.data
                })
                enqueueSnackbar(response?.data?.data?.msg, {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                dispatch(getReferral({ limit: 5, offset: 0 }));
                history.push("/Referral");
                window.location.reload();
            })
            .catch(error => {

                if (error?.response?.status === 406) {
                    enqueueSnackbar("The referral you provided already exists.", {
                        variant: 'info', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
                if (error?.response?.status === 502) {
                    enqueueSnackbar("something went wrong", {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
                if (error?.response?.status === 500) {
                    enqueueSnackbar("Invalid values", {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
            })
    }
};

// export const forgotPassword = (formData) => {
//
//     const requestBody = {
//         mobile: formData.mobile,
//     };
//
//     return (dispatch) => {
//         dispatch({
//             type: FORGOT_LOADING,
//         });
//         reposWebApi.post('/customer/forgot/password', qs.stringify(requestBody))
//             .then(response => {
//                 dispatch({
//                     type: FORGOT_PASSWORD_SUCCESS,
//                     payload: response?.data?.data
//                 });
//             })
//             .catch(error => {
//                 if (error?.response?.status === 401) {
//                     dispatch({
//                         type: SESSION_EXPIRED,
//                     })
//                 }
//                 if (error?.response?.status === 406)
//                     alert("No user found");
//                 window.location.reload();
//                 {
//                     dispatch({
//                         type: FORGOT_PASSWORD_FAIL,
//                     })
//                 }
//                 dispatch({
//                     type: FORGOT_PASSWORD_FAIL,
//                     payload: error
//                 });
//             })
//     }
// };

export const deleteToken = () => {
    let requestBody = {
        refresh_token: localStorage.refresh_token,
        access_token: localStorage.access_token
    };

    return (dispatch) => {
        reposWebApi.post('/access_token/delete', qs.stringify(requestBody), config)
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: SESSION_LOGOUT,
                    });
                    localStorage.clear()
                }
            })

            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                } else if (error?.response?.status === 406) {
                    dispatch({
                        type: LOADING_FALSE,
                    });
                }
            })
    }

};

export const logout = () => {
    return (dispatch) => {
        dispatch({
            type: SESSION_LOGOUT
        });
        dispatch(deleteToken())
        localStorage.clear()
    }
};

export const refreshOutstanding = () => {
    if (token) {
        token.abort();
    }

    token = new AbortController();
    const signal = token.signal;

    return async (dispatch) => {
        await reposWebV2Api.get('/partner/sub_partner_detail', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            signal: signal,
        }).then(res => {
            // localStorage.setItem('userDetails', JSON.stringify(res?.data));
            // localStorage.setItem('notification', parseInt(0));
            dispatch({
                type: REFRESH,
                payload: res?.data?.data
            })
        }).catch(error => {
            if (error?.response?.status === 401) {
                dispatch(refreshToken(details()))
            }
            if (error?.response?.state === 502 || error?.response?.state === 504) {
                dispatch(logout());
            }
        });
    }
};